<template>
  <material-card-sba
    :size.sync="internalSize"
    :order.sync="internalOrder"
    :height.sync="internalHeight"
    :maxOrderValue="maxOrderValue"
    :widgetIcon="widgetIcon"
    color="primary"
    @remove-widget="$emit('remove-widget', $event)"
    @add-widget="$emit('add-widget', $event)"
    :widgetId="widgetId"
    :title="
      $t(
        `components.vue_material_dashboard.items.member_information.my_profile`
      )
    "
  >
    <v-progress-circular
      class="mt-16"
      size="50"
      v-if="loading"
      color="primary"
      indeterminate
    ></v-progress-circular>
    <v-col
      v-else
      class="pa-0 card-link"
      @click.stop="$router.push('member-information')"
    >
      <v-card
        flat
        height="58"
        width="100%"
        color="primary"
        class="white--text rounded-0"
      >
        <!-- <v-col
          cols="12"
          class="text-left text-capitalize text-h6 pa-4 pb-2 pl-8"
          align-self="center"
        >
          {{
            $t(
              `components.vue_material_dashboard.items.member_information.my_profile`
            )
          }}
        </v-col> -->
        <!-- <v-col
          cols="8"
          class="float-right pt-0 pt-md-2 text-subtitle-2 text-md-subtitle-1"
        >
          <span class="mr-1">
            {{
              $t(
                "components.vue_material_dashboard.items.member_information.since"
              )
            }}
          </span>
          {{
            internalMember && $helpers.formatDate(internalMember.PlanEntryDate)
          }}
        </v-col> -->
        <v-row no-gutters justify="end" class="fill-height">
          <v-col
            align-self="end"
            cols="9"
            sm="7"
            md="9"
            :class="`
            text-subtitle-1
            pa-0 pl-2 pb-2
            name-title
            float-right
            font-weight-medium`"
          >
            {{
              $helpers.capitalize(
                internalMember.FirstName + " " + internalMember.LastName
              )
            }}
          </v-col>
        </v-row>
      </v-card>
      <v-card flat height="65px" class="white secondary--text rounded-t-0">
        <v-row no-gutters justify="end" class="mt-2 mr-6 mr-xl-12 pr-xl-6">
          <v-col cols="9" class="text_title pa-0 font-weight-light text-right">
            <span class="text_label">
              {{
                certificateLabel ||
                $t(
                  "components.vue_material_dashboard.items.member_information.certificate"
                )
              }}:
            </span>
            <strong class="font-weight-medium">{{
              userProfile?.Certificate
            }}</strong>
          </v-col>
          <v-col cols="10" class="text_title pa-0 font-weight-light text-right">
            <span class="text_label">
              {{
                $t(
                  "components.vue_material_dashboard.items.member_information.dob"
                )
              }}:
            </span>
            <strong class="font-weight-medium">{{
              $helpers.formatDate(userProfile.Dob)
            }}</strong></v-col
          >
          <v-col
            cols="10"
            class="text-caption pa-0 pr-4 text-uppercase text-right"
            offset="3"
          >
            <div v-if="internalMember.IsEligible">
              <v-chip x-small color="success">
                {{
                  $t(
                    "components.vue_material_dashboard.items.member_information.eligible"
                  )
                }}
              </v-chip>
            </div>
            <div v-else>
              <v-chip
                x-small
                :color="vuetifyTheme.Light.Error"
                class="white--text"
              >
                {{
                  $t(
                    "components.vue_material_dashboard.items.member_information.non-eligible"
                  )
                }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-avatar
      class="avatar"
      :size="
        $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.smAndDown ? 60 : 80
      "
      v-show="internalMember.FirstName"
      color="white"
    >
      <img
        v-if="hasImage"
        :src="'data:image/jpg;base64,' + internalMember.Image"
        alt="member-img"
      />
      <v-avatar v-else color="secondary" size="78">
        <span class="text-h5 white--text">
          {{
            internalMember.FirstName &&
            internalMember.FirstName.charAt(0) +
              (internalMember.LastName && internalMember.LastName.charAt(0))
          }}
        </span>
      </v-avatar>
    </v-avatar>

    <!-- Coverages list -->
    <v-card height="80" flat>
      <v-row
        no-gutters
        justify="center"
        align-content="center"
        class="fill-height pa-2 text-caption"
      >
        {{ formatCoverage(coverages) }}
      </v-row>
    </v-card>

    <!-- Dependants table -->
    <v-card flat height="100">
      <v-row no-gutters align="center">
        <v-col>
          <v-card-text class="" v-show="!loading">
            <v-row no-gutters justify="space-between">
              <v-col
                align-self="center"
                :class="`
                  pa-0
                  ml-4
                  text-left
                  font-weight-bold
                  text-uppercase text-subtitle-2`"
              >
                {{
                  $t(
                    "components.vue_material_dashboard.items.member_information.dependants"
                  )
                }}
              </v-col>
              <!-- <v-col class="pa-0 text-right mr-4">
                <v-btn text small color="primary">
                  <span class="font-weight-bold">{{ $t("globals.edit") }}</span>
                </v-btn>
              </v-col> -->
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-data-table
              mobile-breakpoint="200"
              fixed-header
              height="120"
              :loading="loading"
              :headers="headers"
              :items="internalMember.Dependants"
              hide-default-footer
              class="table pr-0 pl-2"
              :class="scrollbarTheme"
            >
              <template v-slot:[`item.Id`]="{ item }">
                <td class="px-0">
                  {{ item.Id.substr(0, 5) }}
                </td>
              </template>
              <template v-slot:[`item.FirstName`]="{ item }">
                <td class="px-0">
                  <div>{{ item.FirstName }} {{ item.LastName.charAt(0) }}</div>
                  <div class="relationship">
                    <i>
                      {{
                        $helpers.formatDrawbridgeEnumModel(
                          item?.Relationship?.Id
                            ? item?.Relationship?.Id
                            : item?.Relationship,
                          "Relationship",
                          $vuetify.lang.current
                        )
                      }}
                    </i>
                  </div>
                </td>
              </template>
              <template v-slot:[`item.DateOfBirth`]="{ item }">
                <td class="px-0">
                  {{ $helpers.formatDate(item.DateOfBirth) }}
                </td>
              </template>
              <template v-slot:[`item.Eligible`]="{ item }">
                <td class="px-0">
                  <v-icon color="success" v-if="item.Eligible">
                    mdi-check-circle
                  </v-icon>
                  <div v-else class="primary--text">{{ $t("globals.no") }}</div>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </material-card-sba>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MaterialCardSba from "@/components/VueMaterialDashboard/material/MaterialCardSbaDashboard.vue";
export default {
  components: {
    MaterialCardSba,
  },
  props: {
    widgetId: String,
    widgetIcon: String,
    size: Number,
    order: Number,
    height: Number,
    maxOrderValue: Number,
  },
  data() {
    return {
      internalSize: undefined,
      internalOrder: undefined,
      internalHeight: undefined,
      selectedItem: undefined,
      memberData: {},
      loading: true,
      beneficiariesData: [],
      hasImage: true,
      coverages: [],
      internalMember: {
        Beneficiaries: [],
        Dependants: [],
        Coverages: [],
        Image: null,
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("common", [
      "userProfile",
      "certificateLabel",
      "vuetifyTheme",
    ]),
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    headers() {
      return [
        // {
        //   text: "Id",
        //   sortable: false,
        //   value: "Id",
        //   class: "secondary--text text-uppercase pr-0 pl-2",
        //   cellClass: "pl-2 pr-0",
        // },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.name"
          ),
          align: "center",
          sortable: false,
          value: "FirstName",
          class: "secondary--text px-0",
          cellClass: "px-2",
        },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.dob_abvr"
          ),
          align: "center",
          sortable: false,
          value: "DateOfBirth",
          class: "secondary--text text-uppercase px-0",
          cellClass: "px-0",
        },
        // {
        //   text: this.$t(
        //     "components.vue_material_dashboard.items.member_information.relationship"
        //   ),
        //   align: "center",
        //   sortable: false,
        //   value: "Relationship",
        //   class: "secondary--text px-0",
        //   cellClass: "px-2",
        //   width: "20%",
        // },
        {
          text: this.$t(
            "components.vue_material_dashboard.items.member_information.eligible"
          ),
          align: "center",
          sortable: false,
          value: "Eligible",
          class: "secondary--text px-0",
          cellClass: "px-2",
        },
      ];
    },
  },
  watch: {
    userProfile: {
      handler(v) {
        if (v !== null && v !== undefined) {
          this.internalMember = JSON.parse(JSON.stringify(this.userProfile));
          this.beneficiariesData = this.internalMember.Beneficiaries;
          this.hasImage =
            this.internalMember && this.internalMember.Image ? true : false;
          this.coverages = this.internalMember.Coverages;
          this.coverages?.sort((a, b) => {
            if (a.CurrentBenefit.Order < b.CurrentBenefit.Order) {
              return -1;
            }
            if (a.CurrentBenefit.Order > b.CurrentBenefit.Order) {
              return 1;
            }
            return 0;
          });
          this.loading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    size: {
      handler(v) {
        this.internalSize = v;
      },
    },
    internalSize: {
      handler(v) {
        this.$emit("update:size", v);
      },
    },
    height: {
      handler(v) {
        this.internalHeight = v;
      },
      immediate: true,
    },
    internalHeight: {
      handler(v) {
        this.$emit("update:height", v);
      },
    },
    order: {
      handler(v) {
        this.internalOrder = v;
      },
    },
    internalOrder: {
      handler(v) {
        this.$emit("update-order", v);
      },
    },
  },
  mounted() {
    this.beneficiariesData = this.internalMember.Beneficiaries;
    if (this.size !== null && this.size !== undefined) {
      this.internalSize = this.size;
    }
    this.internalHeight = this.height;
    if (this.order !== null && this.order !== undefined) {
      this.internalOrder = this.order;
    }
  },
  methods: {
    ...mapActions("data", ["getMemberData"]),
    formatCoverage(coverages) {
      const resp = [];
      for (const coverage of coverages) {
        if (coverage?.IsWaived) {
          continue;
        }
        let str = this.$helpers.translate2(
          coverage.CurrentBenefit,
          "Name",
          this.$vuetify.lang.current
        );
        if (coverage.CoverageStatusId) {
          str += ` - ${this.$helpers.formatEnumModel(
            coverage.CoverageStatusId,
            "FamilyStatus",
            this.$vuetify.lang.current
          )}`;
          const coverageStatusName = this.$helpers.formatEnumModel(
            coverage.CoverageStatusId,
            "FamilyStatus",
            "en"
          );
          if (
            coverageStatusName == "Family" ||
            coverageStatusName == "Single Parent"
          ) {
            if (
              coverage.NoOfDependants !== null &&
              coverage.NoOfDependants !== undefined
            ) {
              str += ` (${coverage.NoOfDependants})`;
            }
          }
        }
        if (coverage.Volume) {
          str += ` (${coverage.Volume})`;
        }
        resp.push(str);
      }
      return resp.join(", ");
    },
  },
};
</script>
<style scoped>
.avatar {
  position: absolute;
  top: 60px;
  left: 20px;
  border: 3px white solid;
  z-index: 1;
}
.card-link {
  cursor: pointer;
}
.bottom-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.v-application .text-caption {
  font-weight: 600;
  line-height: 0.75rem !important;
  letter-spacing: 0.01em !important;
  font-size: 0.7rem !important;
}
.table >>> .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 0.6rem !important;
  font-weight: 600;
  height: 40px;
}
.table >>> .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.75rem !important;
  font-weight: 600;
  height: 35px;
  box-shadow: inset 0 1px 0 rgb(0 0 0 / 12%), inset 0 -1px 0 rgb(0 0 0 / 12%);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar {
  width: 20px;
  border-width: 5px;
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-track-piece {
  background-color: white;
  border-color: white;
  border-width: 2px 7px 2px 7px;
  border-style: solid;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.light >>> .v-data-table__wrapper::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: var(--primary) !important;
  border-color: white;
  border-style: solid;
  border-width: 1px 4px 1px 4px;
}
.name-title {
  word-break: keep-all !important;
}
.relationship {
  font-size: 8px;
}
.text_title {
  font-size: 0.875rem;
}
.text_subtitle {
  font-size: 0.75rem;
}
.text_label {
  font-size: 0.8rem;
}
</style>
