<template>
  <div>
    <!-- <v-card-title class="title pb-0 mt-2">
      <v-icon color="primary" large class="mr-2">mdi-camera-outline</v-icon>
      {{ $t("components.new_claim.proofs_dialog.library") }}
    </v-card-title> -->
    <v-sheet class="mx-auto" max-width="800">
      <v-slide-group
        v-model="model"
        class="pa-4 pt-0"
        center-active
        show-arrows
      >
        <v-slide-item
          v-for="(proof, i) in proofs"
          :key="proof.FileCategory + ' ' + i"
          v-slot="{ active, toggle }"
        >
          <v-card
            :color="active ? 'accent' : 'grey lighten-2'"
            class="ma-4"
            width="120"
            @click="toggle"
          >
            <v-row
              no-gutters
              align="center"
              @click="$emit('proof-selected', proof)"
            >
              <v-col class="py-2">
                <v-col class="pa-0" :class="active ? 'white--text' : ''"
                  >{{ proof.UploadName }}
                  <v-icon
                    small
                    class="action-icon"
                    :class="active ? 'white--text' : ''"
                    @click.stop="showPreview(proof)"
                    >visibility</v-icon
                  >
                </v-col>
                <v-img
                  v-if="proof.FileSegments[0].FileExtension !== 'pdf'"
                  :src="
                    proof.FileSegments[0].Base64
                      ? 'data:image/jpg;base64,' + proof.FileSegments[0].Base64
                      : null
                  "
                  alt="proof"
                  contain
                  height="130"
                  width="110"
                  class="mx-auto"
                />

                <pdf
                  v-if="proof.FileSegments[0].FileExtension === 'pdf'"
                  :src="
                    'data:application/pdf;base64,' +
                    proof.FileSegments[0].Base64
                  "
                  style="display: inline-block; width: 90px"
                ></pdf>

                <v-col
                  class="pa-0 pt-1 segment"
                  :class="active ? 'white--text' : ''"
                  v-for="(p, j) in proof.FileSegments"
                  :key="p.FileName + ' ' + j"
                >
                  {{ p.FileName }}
                </v-col>
              </v-col>
            </v-row>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <file-preview-base64
      :fullScreen="false"
      v-if="preview"
      :view="preview"
      :fileItems="fileItems"
      @close="closePreview"
    />
  </div>
</template>

<script>
import pdf from "vue-pdf";
import FilePreviewBase64 from "@/components/controls/FilePreviewBase64.vue";
export default {
  components: {
    pdf,
    FilePreviewBase64,
  },
  props: {
    expense: {
      type: Object,
      default: () => {},
    },
    receiptToPreview: {
      type: Object,
      default: () => {},
    },
    proofs: {
      type: Array,
      default: () => [],
    },
    saving: Boolean,
    editMode: Boolean,
    claimValid: Boolean,
  },
  data() {
    return {
      showPreviewDialog: false,
      model: null,
      preview: false,
      fileItems: null,
    };
  },
  watch: {
    receiptToPreview: {
      handler(v) {
        if (v.UploadName) this.showPreview(v);
      },
    },
  },
  methods: {
    showPreview(proof) {
      this.preview = true;
      this.fileItems = proof;
    },
    closePreview() {
      this.preview = false;
      this.$emit("close-preview");
    },
  },
};
</script>
<style scoped>
.v-tooltip__content {
  max-width: 120px !important;
}
.bin-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
}
.v-btn--fab.v-size--x-small {
  height: 20px;
  width: 20px;
}
.preview-image:hover {
  cursor: pointer;
}
.segment {
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
}
.title {
  font-size: 16px;
  font-weight: 600;
}
.action-icon:hover {
  color: var(--primary) !important;
}
</style>
