/* eslint-disable no-unused-vars */
import ApiService from "@/services/api.service";
import SettingsService from "@/services/settings.service.js";
import { translate } from "@/trans";
import store from "@/store";
import mocksData from "./mocksData.json";

const state = {
  hsa: null,
  providerServices: null,
  memberHasSickPay: false,
};

const getters = {
  providerServices: (state) => {
    return state.providerServices;
  },
  memberHasHSA: (state) => {
    var memberHasDemoHSA = store.getters["common/hasDemoHSA"];
    var isDemoMockPlan = store.getters["common/isDemoMockPlan"];
    console.log({ memberHasDemoHSA, isDemoMockPlan });
    if (isDemoMockPlan && memberHasDemoHSA) {
      return true;
    }
    if (isDemoMockPlan && !memberHasDemoHSA) {
      return false;
    }
    return state.hsa?.length > 0;
  },
  memberHasSickPay: (state) => {
    return state.memberHasSickPay;
  },
  memberHSABalance: (state) => {
    return state.hsa?.length && state.hsa[0]?.CurrentBalance;
  },
};

const actions2 = {
  async getSettings({ state }) {
    function sortModel(collection) {
      if (collection === null || collection === undefined) {
        return [];
      }
      const items = collection.sort((a, b) => {
        return translate(a).localeCompare(translate(b));
      });
      return items;
    }
    const settings = SettingsService.settings;
    const url = settings.APIURL + "settings";
    let options = (await ApiService.get(url)).data;
    state.options = options;
    for (let k of Object.keys(state.options.Models)) {
      state.options.Models[k] = sortModel(state.options.Models[k]);
    }
  },
  // eslint-disable-next-line
  getMemberData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  verifyRecaptchaToken({ state }, options) {
    const settings = SettingsService.settings;
    const payload = {
      secret: settings.RecaptchaSecret,
      response: options.token,
    };
    const url = `https://www.google.com/recaptcha/api/siteverify`;
    return ApiService.post(url, payload);
  },
  // eslint-disable-next-line no-unused-vars
  checkValidMember({ state, rootState, rootGetters }, options) {
    const settings = SettingsService.settings;
    let isDemoMockPlan = rootGetters["common/isDemoMockPlan"];
    let isLocalPlan = rootGetters["common/isLocalPlan"];
    if (isDemoMockPlan || isLocalPlan) {
      let mockMember = mocksData.checkValidMemberMock;
      mockMember.data.PersonID =
        "888" + Math.floor(Math.random() * 1000000).toString();
      mockMember.data.MemberAccountID = mockMember.data.PersonID;
      console.log("checkValidMemberPension - demo member", mockMember);
      return mockMember;
    }
    const url = `${settings.APIURL}validate-member`;
    return ApiService.postGuest(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/validate-member`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  // eslint-disable-next-line no-unused-vars
  checkValidMemberSba({ state, rootState, rootGetters }, options) {
    const settings = SettingsService.settings;
    let isDemoMockPlan = rootGetters["common/isDemoMockPlan"];
    let isLocalPlan = rootGetters["common/isLocalPlan"];
    if (isDemoMockPlan || isLocalPlan) {
      let mockMember = mocksData.checkValidMemberSbaMock;
      mockMember.data.Certificate = Math.floor(Math.random() * 1000000000);
      console.log("checkValidMemberSBA - demo member", mockMember);
      return mockMember;
    }
    const url = `${settings.APIURL}plans/${options.planIdentityId}/validate-drawbridge-member`;
    return ApiService.postGuest(url, options.payload);
  },
  // eslint-disable-next-line no-unused-vars
  checkValidEmail({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}validate-email`;
    return ApiService.post(url, options.payload);
  },
  // eslint-disable-next-line
  updateMemberPassword({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-password`;
    return ApiService.post(url, options.payload);
  },
  // eslint-disable-next-line
  updateMemberUsername({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-username`;
    return ApiService.post(url, options.payload);
  },
  // eslint-disable-next-line
  getMemberBeneficiaries({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/beneficiaries`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  saveMemberBeneficiaries({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/beneficiaries`;
    return ApiService.post(url, options.payload);
  },
  // eslint-disable-next-line
  getMemberPensionData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/pension`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getDownloadCentreData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/download-centre`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getFaqData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/faq`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getMemberSettings({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/settings`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getAccountPendingData({ state }, options) {
    console.log("getting pending data");
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/account-pending`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getPensionLastUpdateData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/pension-last-update`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getDocumentsNewData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/documents-new`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getResourcesNewData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/resources-new`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getNewsData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/news`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getPensionSummaryData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/pension-summary`;
    return ApiService.get(url);
  },
  getAssetsData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/assets`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getDownloadsData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/downloads`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  getBeneficiariesData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/beneficiaries`;
    return ApiService.get(url);
  },
  getClaimsData({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/claims`;
    return ApiService.get(url);
  },
  getBenefitCards({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/benefit-cards`;
    return ApiService.get(url);
  },
  getHoursBankBalance({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}eligibility-bank/${options.memberIdentityId}/current-balance`;
    return ApiService.get(url);
  },
  getHoursBankHistory({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}eligibility-bank/${options.memberIdentityId}/history`;
    return ApiService.get(url);
  },
  getSelfPayment({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}self-payments/${options.id}?$expand=SelfPaymentCondition,SelfPaymentSetting($expand=SelfPaymentConditions)
`;
    return ApiService.get(url);
  },
  getSickPay({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/sick-hour-bank`;
    return ApiService.get(url);
  },
  getBenefitBalancesLegacy({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/benefit-balances`;
    return ApiService.get(url);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/benefit-balances`;
    // return ApiService.getDeployedProxy(url);
  },
  getBenefitBalancesDrawbridge({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}benefit-balance/fetch`;
    return ApiService.post(url, options.payload);
    // const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/benefit-balance/fetch`;
    // return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/benefit-balance/fetch`;
    // return ApiService.getDeployedProxy(url, options.payload);
  },
  getMostFrequentBenefitBalancesDrawbridge({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}benefit-balance/most-frequent`;
    return ApiService.post(url, options.payload);
  },
  getHSABalances({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/HSA?$select=CurrentBalance&$expand=Plan,Credits,HSACreditToExpire`;
    return ApiService.get(url);
  },
  async getHSA({ state }, options) {
    const settings = SettingsService.settings;
    //if (state.hsa) return state.hsa;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/HSA?$select=CurrentBalance&$expand=Plan,Credits($expand=Transactions),HSACreditToExpire`;
    var isDemoMockPlan = store.getters["common/isDemoMockPlan"];
    if (isDemoMockPlan) {
      state.hsa = mocksData?.getHsaMock?.data?.value;
      console.log("getHSA - demo hsa", state.hsa);
      return state.hsa;
    } else {
      state.hsa = (await ApiService.get(url)).data?.value;
    }
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planId}/members/${options.memberIdentityId}/HSA?$select=CurrentBalance&$expand=Plan,Credits,HSACreditToExpire`;
    // state.hsa = (await ApiService.getDeployedProxy(url)).data?.value;
    return state.hsa;
  },
  getHSACredits({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/HSACredits`;
    return ApiService.get(url);
  },
  getPlanBenefits({ state }, options) {
    const settings = SettingsService.settings;
    let url = `${settings.APIURL}plans/${
      options.planIdentityId
    }/plan-benefit/members/${options.memberIdentityId}/hsa/${
      options.hasHSA ? "true" : "false"
    }`;
    return ApiService.get(url);
  },
  getGroupBenefits({ state }, options) {
    const settings = SettingsService.settings;
    // let url = `${settings.APIURL}plans/${options.planIdentityId}/plan-benefit/${
    //   options.hasHSA ? "true" : "false"
    // }/${options.memberGroupId}`;
    let url = `${settings.APIURL}plans/${
      options.planIdentityId
    }/plan-benefit/member-group/${options.memberGroupId}/effective-date/${
      options.effectiveDate
    }/hsa/${options.hasHSA ? "true" : "false"}`;
    console.log("get plan benefits options:", options, "url", url);
    return ApiService.get(url);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${
    //   options.planIdentityId
    // }/plan-benefit/${options.hasHSA ? "true" : "false"}/${
    //   options.memberGroupId
    // }`;
    // return ApiService.getDeployedProxy(url);
  },
  getSickDaySubmissionSettings({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}sick-day-submission-settings/member-group/${options.memberGroupId}/effective-date/${options.effectiveDate}`;
    return ApiService.get(url);
  },
  getMemberDependants({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/member-patients`;
    return ApiService.get(url);
  },
  getClaimDates({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/validate-member-patient-benefit`;
    return ApiService.post(url, options.payload);
  },
  getNotifications({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/notifications`;
    return ApiService.get(url);
  },
  searchDrugs({ state }, options) {
    const settings = SettingsService.settings;
    const nameQuery = options.name
      ? `contains(tolower(Name), '${options.name}')`
      : "";
    const dinQuery = options.din
      ? `contains(tolower(cast(Din, Edm.String)), '${options.din}')`
      : "";
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/drugs/${options.memberGroupId}`;
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/drugs/${options.memberGroupId}`;
    let pagination = "";
    if (options.itemsPerPage > 0) {
      pagination = `?$count=true&$skip=${
        options.itemsPerPage * (options.page - 1)
      }&$top=${options.itemsPerPage}`;
    } else {
      pagination = `?$count=true`;
    }

    const sort = options.sortBy
      ? `&$orderby=${options.sortBy} ${options.sortDesc ? "desc" : "asc"}`
      : "";
    const query =
      "&$filter=" + nameQuery + (nameQuery && dinQuery ? "&" : "") + dinQuery;
    return ApiService.get(url + pagination + sort + query);
    // return ApiService.getDeployedProxy(url + pagination + sort + query);
  },

  getPaidClaims({ state }, options) {
    const settings = SettingsService.settings;
    let filters = [];
    if (options.claimChequeNo) {
      // contains(cast(ClaimNumber, Edm.String ), '32842')
      filters.push(
        `contains(cast(ClaimNumber, Edm.String), '${options.claimChequeNo}') or contains(cast(ChequeNumber, Edm.String), '${options.claimChequeNo}')`
      );
    }

    if (options.patients) {
      filters.push(
        `ClaimHistoryDetails/any(__c_h: contains(tolower(__c_h/PatientName), '${options.patients.toLowerCase()}'))`
      );
    }

    if (options.statusDates && options.statusDates.length === 2) {
      filters.push(
        `StatusDate ge ${
          options.statusDates[0] + "T00:00:00.0000000Z"
        } and StatusDate le ${options.statusDates[1] + "T23:59:59.999999Z"}`
      );
    }

    if (options.statusDates && options.statusDates.length === 1) {
      let date = new Date(options.statusDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `StatusDate ge ${
          options.statusDates[0] + "T00:00:00.0000000Z"
        } and StatusDate lt ${date.toISOString()}`
      );
    }

    if (options.paidTo) {
      if (options.paidTo === "M") {
        filters.push(`PaidTo eq 'M'`);
      } else {
        filters.push(`PaidTo ne 'M'`);
      }
    }

    if (options.dateOfServiceDates.length === 2) {
      filters.push(
        `ClaimHistoryDetails/any(__c_h: __c_h/DateOfService ge ${
          options.dateOfServiceDates[0] + "T00:00:00.0000000Z"
        } and __c_h/DateOfService le ${
          options.dateOfServiceDates[1] + "T23:59:59.999999Z"
        })`
      );
    }

    if (options.dateOfServiceDates.length === 1) {
      let date = new Date(options.dateOfServiceDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `ClaimHistoryDetails/any(__c_h: __c_h/DateOfService ge ${
          options.dateOfServiceDates[0] + "T00:00:00.0000000Z"
        } and __c_h/DateOfService lt ${date.toISOString()})`
      );
    }

    if (!options.selectAmountRange && options.claimedAmount) {
      filters.push(`TotalSubmitted eq ${options.claimedAmount}`);
    }

    if (options.selectAmountRange && options.claimedAmountRange) {
      filters.push(
        `TotalSubmitted ge ${options.claimedAmountRange[0]} and TotalSubmitted le ${options.claimedAmountRange[1]}`
      );
    }

    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/claims`;
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/claims-summary`;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/claims-summary`;
    const expandQuery = "?$expand=ClaimHistoryDetails";
    const pagination = `&$count=true&$skip=${
      options.itemsPerPage * (options.page - 1)
    }&$top=${options.itemsPerPage}`;
    const sort = options.sortBy
      ? `&$orderby=${options.sortBy} ${options.sortDesc ? "desc" : "asc"}`
      : "";

    let filter = `&$filter=(StudentNumber eq '${options.studentNumber}')`;
    let additionalFilters =
      filters.length !== 0
        ? " and " +
          filters
            .map((o) => {
              return `(${o})`;
            })
            .join(" and ")
        : "";

    // console.log(
    //   "searching paid claims Filter",
    //   expandQuery + pagination + sort + filter + additionalFilters
    // );
    return ApiService.get(
      url + expandQuery + pagination + sort + filter + additionalFilters
    );
    // return ApiService.getDeployedProxy(
    //   url + expandQuery + pagination + sort + filter + additionalFilters
    // );
  },
  searchClaims({ state }, options) {
    // console.log("search options", options);
    const settings = SettingsService.settings;
    let filters = [];
    if (options.submissionId) {
      filters.push(
        `contains(tolower(Identifier), '${options.submissionId.toLowerCase()}')`
      );
    }

    // Timestamp ge 2022-04-16T00:00:00.0000000Z and Timestamp le 2022-10-16T23:59:59.999999Z
    if (options.submissionDates && options.submissionDates.length === 2) {
      filters.push(
        `Timestamp ge ${
          options.submissionDates[0] + "T00:00:00.0000000Z"
        } and Timestamp le ${options.submissionDates[1] + "T23:59:59.999999Z"}`
      );
    }

    if (options.submissionDates && options.submissionDates.length === 1) {
      let date = new Date(options.submissionDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `Timestamp ge ${
          options.submissionDates[0] + "T00:00:00.0000000Z"
        } and Timestamp lt ${date.toISOString()}`
      );
    }

    if (options.expenseDates.length === 2) {
      filters.push(
        `Claims/any(__c_t: __c_t/DateOfService ge ${
          options.expenseDates[0] + "T00:00:00.0000000Z"
        } and __c_t/DateOfService le ${
          options.expenseDates[1] + "T23:59:59.999999Z"
        })`
      );
    }

    if (options.expenseDates.length === 1) {
      let date = new Date(options.expenseDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `Claims/any(__c_t: __c_t/DateOfService ge ${
          options.expenseDates[0] + "T00:00:00.0000000Z"
        } and __c_t/DateOfService lt ${date.toISOString()})`
      );
    }

    if (options.patientIds?.length) {
      console.log("patientIds", options.patientIds);
      let idsString = "";
      options.patientIds.forEach((id, i) => {
        if (id) {
          idsString += id + ", ";
        } else {
          idsString += "null, ";
        }
      });
      idsString = idsString.slice(0, -2);
      filters.push(`Claims/any(__c_t: __c_t/DependantId in (${idsString}))`);
    }

    if (options.coverages.length) {
      filters.push(
        `Claims/any(__c_t: __c_t/BenefitId in (${options.coverages.join(
          ", "
        )}))`
      );
    }

    if (options.status) {
      filters.push(`Status eq ${options.status}`);
    }

    if (!options.selectAmountRange && options.claimedAmount) {
      filters.push(`ClaimsTotal eq ${options.claimedAmount}`);
    }

    if (options.selectAmountRange && options.claimedAmountRange) {
      filters.push(
        `ClaimsTotal ge ${options.claimedAmountRange[0]} and ClaimsTotal le ${options.claimedAmountRange[1]}`
      );
    }

    const filter = filters.length ? "&$filter=" + filters.join(" and ") : "";

    const url = `${settings.APIURL}plans/${options.planIdentityId}/claim-submission/${options.memberIdentityId}`;
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/claim-submission/${options.memberIdentityId}`;

    const expandQuery =
      "?$expand=Proofs($expand=FileSegments)&$expand=Claims($expand=Benefit)";
    const pagination = `&$count=true&$skip=${
      options.itemsPerPage * (options.page - 1)
    }&$top=${options.itemsPerPage}`;
    const sort = options.sortBy
      ? `&$orderby=${options.sortBy} ${options.sortDesc ? "desc" : "asc"}`
      : "";

    // console.log(
    //   "searching submitted claims Filter",
    //   expandQuery + pagination + sort + filter
    // );
    return ApiService.get(url + expandQuery + pagination + sort + filter);
    // return ApiService.getDeployedProxy(
    //   url + expandQuery + pagination + sort + filter
    // );
  },
  searchDrawbridgeClaims({ state }, options) {
    console.log("search options", options);
    const settings = SettingsService.settings;
    let filters = [];

    // request was made to filter out claims which are not predetermination claims - IsPredetermined : true
    filters.push(`IsPredetermined ne true`);

    if (options.submissionId) {
      filters.push(
        `contains(tolower(Identifier), '${options.submissionId
          .toLowerCase()
          .replaceAll("'", "''")}')`
      );
    }

    // Timestamp ge 2022-04-16T00:00:00.0000000Z and Timestamp le 2022-10-16T23:59:59.999999Z
    if (options.submissionDates && options.submissionDates.length === 2) {
      filters.push(
        `Timestamp ge ${
          options.submissionDates[0] + "T00:00:00.0000000Z"
        } and Timestamp le ${options.submissionDates[1] + "T23:59:59.999999Z"}`
      );
    }

    if (options.submissionDates && options.submissionDates.length === 1) {
      let date = new Date(options.submissionDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `Timestamp ge ${
          options.submissionDates[0] + "T00:00:00.0000000Z"
        } and Timestamp lt ${date.toISOString()}`
      );
    }

    if (options.expenseDates?.length === 2) {
      filters.push(
        `Claims/any(__c_t: __c_t/CurrentClaimData/DateOfService ge ${
          options.expenseDates[0] + "T00:00:00.0000000Z"
        } and __c_t/CurrentClaimData/DateOfService le ${
          options.expenseDates[1] + "T23:59:59.999999Z"
        })`
      );
    }

    if (options.expenseDates?.length === 1) {
      let date = new Date(options.expenseDates[0]);
      date.setDate(date.getDate() + 1);
      filters.push(
        `Claims/any(__c_t: __c_t/CurrentClaimData/DateOfService ge ${
          options.expenseDates[0] + "T00:00:00.0000000Z"
        } and __c_t/CurrentClaimData/DateOfService lt ${date.toISOString()})`
      );
    }

    if (options.patient) {
      // console.log("patient", options.patient);
      // filters.push(`Claims/any(__c_t: __c_t/Dependant/FirstName in (${idsString}))`);
      const parts = options.patient.split(" ");
      for (let i = 0; i < parts.length; i++) {
        filters.push(
          `Claims/any(__c1_t: contains(tolower(__c1_t/FirstName), '${parts[i]
            .toLowerCase()
            .replaceAll(
              "'",
              "''"
            )}') or Claims/any(__c2_t: contains(tolower(__c2_t/LastName), '${parts[
            i
          ]
            .toLowerCase()
            .replaceAll("'", "''")}')))`
        );
      }
    }

    // Only get ClaimSubmissions that have no empty Claims and have no Claims with status 'new' or 'deleted'
    filters.push("Claims/any()");
    filters.push(
      "Claims/any(__c_t: not contains(tolower(__c_t/State), 'new'))"
    );
    filters.push(
      "Claims/any(__c_t: not contains(tolower(__c_t/State), 'deleted'))"
    );
    if (options.status) {
      console.log("status", options.status);
      // filters.push(`Claims/any(__c_t: __c_t/Dependant/FirstName in (${idsString}))`);

      filters.push(
        `Claims/any(__c_t: contains(tolower(__c_t/State), '${options.status
          .toLowerCase()
          .replaceAll("'", "''")}'))`
      );
    }

    if (options.coverages?.length) {
      filters.push(
        `Claims/any(__c_t: __c_t/CurrentClaimData/PlanBenefitId in (${options.coverages.join(
          ", "
        )}))`
      );
    }

    if (!options.selectAmountRange && options.claimedAmount) {
      filters.push(
        `Claims/any(__c_t: __c_t/CurrentClaimData/ClaimedAmount eq ${options.claimedAmount})`
      );
    }

    if (options.selectAmountRange && options.claimedAmountRange) {
      filters.push(
        `Claims/any(__c_t: __c_t/CurrentClaimData/ClaimedAmount ge ${options.claimedAmountRange[0]} and __c_t/CurrentClaimData/ClaimedAmount le ${options.claimedAmountRange[1]})`
      );
    }

    const filter = filters.length ? "&$filter=" + filters.join(" and ") : "";

    const url = `${settings.APIURL}plans/${options.planIdentityId}/claim-submission-drawbridge`;
    //const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/claim-submission-drawbridge`;

    const expandQuery =
      "?$expand=Claims($expand=Proofs($expand=FileSegments),CurrentClaimData($expand=PlanBenefit($expand=Benefit($select=Name;$expand=Translations))))";
    const pagination = `&$count=true&$skip=${
      options.itemsPerPage * (options.page - 1)
    }&$top=${options.itemsPerPage}`;
    const sort = options.sortBy
      ? `&$orderby=${options.sortBy} ${options.sortDesc ? "desc" : "asc"}`
      : "";

    // console.log(
    //   "searching submitted claims Filter",
    //   expandQuery + pagination + sort + filter
    // );
    return ApiService.get(url + expandQuery + pagination + sort + filter);
    // return ApiService.getDeployedProxy(
    //   url + expandQuery + pagination + sort + filter
    // );
  },

  async getProviderServices({ state }, options) {
    if (!state.providerServices) {
      const settings = SettingsService.settings;
      // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/providerservices?$Expand=Translations`;
      // const res = await ApiService.getDeployedProxy(url);
      const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/providerservices?$Expand=Translations`;
      const res = await ApiService.get(url);
      state.providerServices = res.data.value;
    }
    return new Promise((resolve) => resolve(state.providerServices));
    // return ApiService.get(url);
    //return ApiService.getDeployedProxy(url);
  },
  searchProvider({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/search-provider?location=${options.payload.location}&service=${options.payload.service}`;
    return ApiService.get(url);
  },
  // eslint-disable-next-line
  updateNotifications({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/notifications`;
    return ApiService.post(url, options.payload);
  },
  sendMessage({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/send-message`;
    return ApiService.post(url, options.payload);
  },
  sendHtml({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/send-html`;
    return ApiService.post(url, options.payload);
  },
  sendBenefitCardByEmail({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/benefit-cards/send`;
    return ApiService.post(url, options.payload);
  },
  submitClaim({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/claim-submission`;
    return ApiService.post(url, options.payload);
  },
  canadaPostFind({ state }, options) {
    const settings = SettingsService.settings;
    // const url = `${settings.CanadaPostURL}Find/v2.10/json3.ws`;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/canada-post/find`;
    let query = `?SearchTerm=${options.payload.SearchTerm}&Country=${options.payload.Country}&LanguagePreference=${options.payload.LanguagePreference}`;
    if (options.payload.LastId) {
      query += "&LastId=" + options.payload.LastId;
    }
    return ApiService.get(url + query);
  },
  canadaPostRetrieve({ state }, options) {
    const settings = SettingsService.settings;
    // const url = `${settings.CanadaPostURL}Retrieve/v2.11/json3.ws`;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/canada-post/retrieve`;
    const query = `?Id=${options.payload.Id}&LanguagePreference=${options.payload.LanguagePreference}`;
    return ApiService.get(url + query);
  },
  // canadaPostFind({ state }, options) {
  //   const settings = SettingsService.settings;
  //   const url = `${settings.CanadaPostURL}Find/v2.10/json3.ws`;
  //   let query = `?Key=${options.payload.Key}&SearchTerm=${options.payload.SearchTerm}&Country=${options.payload.Country}&LanguagePreference=${options.payload.LanguagePreference}`;
  //   if (options.payload.LastId) {
  //     query += "&LastId=" + options.payload.LastId;
  //   }
  //   return ApiService.getCanadaPost(url + query);
  // },
  // canadaPostRetrieve({ state }, options) {
  //   const settings = SettingsService.settings;
  //   const url = `${settings.CanadaPostURL}Retrieve/v2.11/json3.ws`;
  //   const query = `?Key=${options.payload.Key}&Id=${options.payload.Id}&LanguagePreference=${options.payload.LanguagePreference}`;
  //   return ApiService.getCanadaPost(url + query);
  // },
  // async validateBankAccount({ commit }, options) {
  //   const settings = SettingsService.settings;
  //   const authUrl = settings.PaymentsApiAuthUri; //"https://api.payments.ca/accesstoken";
  //   // const authPayload = {
  //   //   Username: "V13jM95IfIqk0F8NxUlQSFnLi0DFoVKF",//"KtotzkxAovlnzw017OQ3YogVr7aNb6ut",
  //   //   Password: "WXHKD3GAZSNst0PH",//"B9D1YeCBCKpDbWWA",
  //   // };
  //   const authPayload = {
  //     Username: settings.PaymentsApiUser,
  //     Password: settings.PaymentsApiPass,
  //   };
  //   const token = (
  //     await ApiService.postPaymentsForm(authUrl, authPayload, {
  //       grant_type: "client_credentials",
  //     })
  //   ).data.access_token;
  //   const url = `${settings.PaymentsApiUri}${options.payload.InstitutionNumber}${options.payload.TransitNumber}`;
  //   return ApiService.getPayments(url, token);
  // },
  validateBankAccount({ commit }, options) {
    const settings = SettingsService.settings;
    const url = settings.APIURL + "bank-accounts/validate";
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/bank-accounts/validate`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  googleMapsSearch({ state }, options) {
    const settings = SettingsService.settings;
    let query = "";
    if (options.nexPageToken) {
      query = `?pagetoken=${options.nexPageToken}&key=${settings.GoogleAPIKEY}`;
    } else {
      query = `?query=${options.keyword}&location=${options.lat},${options.lng}&radius=${options.radius}&key=${settings.GoogleAPIKEY}`;
    }
    const url = `https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/textsearch/json`;
    //const url = `https://maps.googleapis.com/maps/api/place/textsearch/json`;
    return ApiService.getNoAuth(url + query);
  },
  // eslint-disable-next-line
  verifyMember({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/verify`;
    return ApiService.put(url, options.payload);
    //   const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/verify`;
    //   return ApiService.putDeployedProxy(url, options.payload);
  },
  // eslint-disable-next-line
  saveMember({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  saveMemberAddress({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-address`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-address`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  saveMemberContactInfo({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-contacts`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-contacts`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  saveMemberCommunicationInfo({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-communication`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/save-communication`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  updateMemberInfoOnAllPlans({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-member-all-plans`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-member-all-plans`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  // eslint-disable-next-line
  updateContactInfo({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-contact-info`;
    return ApiService.put(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-contact-info`;
    // return ApiService.putDeployedProxy(url, options.payload);
  },
  // eslint-disable-next-line
  updateBankAccount({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-bank-account`;
    return ApiService.put(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-bank-account`;
    // return ApiService.putDeployedProxy(url, options.payload);
  },
  // eslint-disable-next-line
  getDocumentFile({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/upload-files/get-files`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/upload-files/get-files`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  getFile({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/upload-files/${options.physicalFileId}`;
    return ApiService.get(url);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/upload-files/${options.physicalFileId}`;
    // return ApiService.getDeployedProxy(url);
  },
  getMultipleFilesZip({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/upload-files/get-files`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/upload-files/get-files`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  getFilesIds({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/upload-files/get-filesIds`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/upload-files/get-filesIds`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  getMemberFields({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/member-fields`;
    return ApiService.get(url, options.payload);
    // const url = `https://uat.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/member-fields`;
    // return ApiService.getDeployedProxy(url);
  },
  shareFileByEmail({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/share-files`;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/share-files`;
    // return ApiService.postDeployedProxy(url, options.payload);
  },
  changeEmail({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/change-email`;
    options.payload.ReturnUrl = window.location.origin;
    return ApiService.post(url, options.payload);
    // const url = `https://dev.mapi.pbasconnect.com/plans/${options.planIdentityId}/members/${options.memberIdentityId}/change-email`;
    // return ApiService.postDeployedProxy(url, options.payload);
    //const url = `http://localhost:5024/api/authenticate/ChangeEmail`;
    //return ApiService.post(url, options.payload);
  },
  // getHSA({ state }, options) {
  //   const settings = SettingsService.settings;
  //   const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/HSA`;
  //   return ApiService.get(url);
  // }
  getPlanCoveredBenefits({ state }, options) {
    const settings = SettingsService.settings;
    const [date, time] = options.effectiveDate.split("T");
    const [year, month, day] = date.split("-");
    const dateFormatted = `${year}-${month}-${day}T00:00:00.000Z`;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/effective-date/${dateFormatted}/covered-benefits?$expand=Benefit`;
    return ApiService.get(url);
  },
  getHsaCoveredBenefits({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/covered-benefits/${options.coveredBenefitId}?$expand=Benefit,LifestyleBenefit`;
    return ApiService.get(url);
  },

  getMemberGroupIdentityId({ state }, options) {
    const settings = SettingsService.settings;
    const [date, time] = options.date.split("T");
    const [year, month, day] = date.split("-");
    const dateFormatted = `${year}-${month}-${day}T00:00:00.000Z`;
    const url = `${settings.APIURL}members/${options.memberIdentityId}/date/${dateFormatted}/member-group-identity-id`;
    return ApiService.get(url);
  },

  setMockHSA({ state }, value) {
    console.log("setting mock HSA");
    state.hsa = value;
  },

  addDependant({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/add-dependant`;
    return ApiService.post(url, options.payload);
  },
  updateDependant({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-dependant`;
    return ApiService.put(url, options.payload);
  },
  deleteDependant({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/delete-dependant`;
    return ApiService.put(url, options.payload);
  },
  deleteAllDependants({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/delete-all-dependants`;
    return ApiService.put(url, options.payload);
  },
  updateBeneficiaries({ state }, options) {
    const settings = SettingsService.settings;
    const url = `${settings.APIURL}plans/${options.planIdentityId}/members/${options.memberIdentityId}/update-beneficiaries`;
    return ApiService.put(url, options.payload);
  },
};

const mutations = {};

const mocks = mocksData;

// For Demo portals return mock data
const actions = {};
Object.keys(actions2).forEach((k) => {
  actions[k] = (s, options) => {
    // console.log(s, options);
    if (store.getters["common/isDemoMockPlan"]) {
      //console.log("data.store: This is the Demo Mock Plan");
      if (mocks[k]) return mocks[k];
    }
    // console.log(actions2[k]);
    return actions2[k](s, options);
  };
});

export const data = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
