<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-card :loading="saving ? 'secondary' : false">
        <v-card-title class="primary white--text mb-6">
          <v-icon dark class="mr-2">{{ titleIcon }}</v-icon>
          {{ title }}
        </v-card-title>
        <v-card-text class="px-5 pb-0">
          <v-row class="pt-2">
            <v-col cols="12" md="4" class="py-0">
              <v-select
                outlined
                :label="$t('components.beneficiary_sba.headers.type')"
                v-model="internalBeneficiary.BeneficiaryTypeId"
                @change="setBeneficiaryType($event)"
                :items="beneficiaryTypes"
                class="required"
                :rules="[$rules.required]"
                return-object
                item-value="Id"
                :item-text="
                  (item) => $helpers.translate(item, $vuetify.lang.current)
                "
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" class="py-0" v-if="benIsPerson">
              <v-autocomplete
                outlined
                :label="$t('components.beneficiary_form.relationship')"
                :items="relationList"
                @change="enableAddNewBeneficiary"
                v-model="internalBeneficiary.BeneficiaryRelationshipId"
                :rules="[$rules.required]"
                item-value="Id"
                :item-text="
                  (item) => $helpers.translate(item, $vuetify.lang.current)
                "
                class="required"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="
                benIsPerson && internalDependants?.length > 0 && isSpouseOrChild
              "
            >
              <v-autocomplete
                outlined
                :label="$t('components.beneficiary_form.add_from_dependants')"
                :items="internalDependants"
                v-model="selectedDependant"
                @change="fillUpDependantToBeneficiary"
                return-object
                item-text="FullName"
                clearable
              >
                <template v-slot:prepend>
                  <v-btn
                    small
                    rounded
                    color="primary"
                    :disabled="addingNewBeneficiary || !!selectedDependant"
                    @click="enableAddNewBeneficiary"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="py-0"
              v-if="
                (benIsPerson && !isSpouseOrChild) ||
                internalDependants?.length === 0
              "
            >
            </v-col>
            <template v-if="internalBeneficiary.BeneficiaryTypeId">
              <v-col cols="12" md="4" class="py-0" v-if="isCorporate">
                <no-autocomplete-textfield
                  outlined
                  :label="$t('components.beneficiary_form.name')"
                  v-model="internalBeneficiary.FirstName"
                  :rules="[$rules.required, $rules.validName]"
                  class="required"
                ></no-autocomplete-textfield>
              </v-col>
              <template
                v-if="
                  (benIsPerson && addingNewBeneficiary) ||
                  !isSpouseOrChild ||
                  internalDependants?.length === 0
                "
              >
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  v-if="
                    (benIsPerson || isCorporate) &&
                    internalBeneficiary.BeneficiaryRelationshipId
                  "
                >
                  <no-autocomplete-textfield
                    outlined
                    :label="
                      benIsPerson
                        ? $t('components.beneficiary_form.first_name')
                        : $t('components.beneficiary_form.name')
                    "
                    v-model="internalBeneficiary.FirstName"
                    :rules="[$rules.required, $rules.validName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  v-if="
                    benIsPerson && internalBeneficiary.BeneficiaryRelationshipId
                  "
                >
                  <no-autocomplete-textfield
                    outlined
                    :label="$t('components.beneficiary_form.middle_name')"
                    v-model="internalBeneficiary.MiddleName"
                    :rules="[$rules.validMiddleName]"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  v-if="
                    benIsPerson && internalBeneficiary.BeneficiaryRelationshipId
                  "
                >
                  <no-autocomplete-textfield
                    outlined
                    :label="$t('components.beneficiary_form.last_name')"
                    v-model="internalBeneficiary.LastName"
                    :rules="[$rules.required, $rules.validLastName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  v-if="
                    benIsPerson && internalBeneficiary.BeneficiaryRelationshipId
                  "
                >
                  <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="internalBeneficiary.DateOfBirth"
                        :label="$t('components.beneficiary_form.date_of_birth')"
                        :rules="isChild ? [$rules.required] : []"
                        :class="isChild ? 'required' : ''"
                        append-icon="mdi-calendar"
                        @click:append="dateMenu = true"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="internalBeneficiary.DateOfBirth"
                      :max="$helpers.toISOStringLocal(new Date())"
                      :picker-date.sync="pickerDate"
                      @input="dateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </template>
              <v-col
                cols="12"
                md="4"
                class="py-0"
                v-if="
                  !benIsPerson || internalBeneficiary.BeneficiaryRelationshipId
                "
              >
                <currency-textfield
                  :label="$t('components.beneficiary_sba.headers.share')"
                  prefix="%"
                  outlined
                  clearable
                  v-model="internalBeneficiary.Percentage"
                  min="0"
                  class="required"
                  :rules="[$rules.requiredNum, $rules.dynamic.maxValue(100)]"
                />
              </v-col>
              <v-col cols="12" md="4" class="py-0">
                <v-select
                  outlined
                  :label="
                    $t('components.beneficiary_sba.headers.beneficiary_type')
                  "
                  v-model="internalBeneficiary.IsPrimary"
                  :items="beneficiarySelection"
                  class="required"
                  :rules="[requiredBoolean]"
                  item-value="Value"
                  :item-text="
                    $vuetify.lang.current === 'fr' ? 'TextFr' : 'TextEn'
                  "
                ></v-select>
              </v-col>
              <v-col cols="4" class="py-0" v-if="isSpouse">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-container pa-0>
                      <v-row no-gutters>
                        <v-col cols="auto">
                          <v-checkbox
                            v-model="internalBeneficiary.IsRevocable"
                            :label="
                              $t('components.beneficiary_sba.headers.revocable')
                            "
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          style="margin-top: 20px; margin-left: 3px"
                          cols="auto"
                        >
                          <v-icon v-bind="attrs" v-on="on">help</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <span>
                    {{ $t("components.beneficiary_sba.tooltip") }}
                  </span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="4"
                class="py-0"
                v-if="benIsPerson && requiresTrustee"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-container pa-0>
                      <v-row no-gutters>
                        <v-col cols="auto">
                          <v-checkbox
                            :readonly="requiresTrustee"
                            @change="setTrusteeType()"
                            v-model="internalBeneficiary.AddTrustee"
                            :label="
                              $t(
                                'components.beneficiary_sba.headers.add_trustee'
                              )
                            "
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          style="margin-top: 20px; margin-left: 3px"
                          cols="auto"
                        >
                          <v-icon v-bind="attrs" v-on="on">help</v-icon>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <span>
                    {{ $t("components.beneficiary_sba.trustee_tooltip") }}
                  </span>
                </v-tooltip>
              </v-col>
              <!-- Add trustee fields -->
              <v-row
                no-gutters
                class="pt-4 px-2"
                v-if="internalBeneficiary.AddTrustee"
              >
                <v-col
                  v-if="requiresTrustee"
                  cols="12"
                  class="red--text pb-4 text-left pl-4"
                  >{{ $t(requiresTrusteeNote) }}</v-col
                >
                <v-col
                  cols="12"
                  md="4"
                  v-if="
                    !trusteeIsPerson ||
                    internalBeneficiary?.Trustee.BeneficiaryRelationshipId !==
                      null
                  "
                >
                  <no-autocomplete-textfield
                    outlined
                    :label="
                      trusteeIsPerson
                        ? $t('components.beneficiary_form.first_name')
                        : $t('components.beneficiary_form.name')
                    "
                    v-model="internalBeneficiary.Trustee.FirstName"
                    :rules="[$rules.required, $rules.validName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="px-4"
                  v-if="
                    trusteeIsPerson &&
                    internalBeneficiary.Trustee?.BeneficiaryRelationshipId !==
                      null
                  "
                >
                  <no-autocomplete-textfield
                    outlined
                    :label="$t('components.beneficiary_form.last_name')"
                    v-model="internalBeneficiary.Trustee.LastName"
                    :rules="[$rules.required, $rules.validLastName]"
                    class="required"
                  ></no-autocomplete-textfield>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="py-0"
                  v-if="
                    trusteeIsPerson &&
                    internalBeneficiary.Trustee?.BeneficiaryRelationshipId !==
                      null
                  "
                >
                  <v-menu
                    v-model="dateMenu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="internalBeneficiary.Trustee.DateOfBirth"
                        :label="$t('components.beneficiary_form.date_of_birth')"
                        :rules="[$rules.required]"
                        class="required"
                        append-icon="mdi-calendar"
                        @click:append="dateMenu1 = true"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="internalBeneficiary.Trustee.DateOfBirth"
                      :picker-date.sync="pickerDate"
                      :max="minimumDateOfBirthAboveMajority"
                      @input="dateMenu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" class="py-0" v-if="trusteeIsPerson">
                  <v-autocomplete
                    outlined
                    :label="
                      $t('components.beneficiary_sba.trustee_relationship')
                    "
                    :items="relationList"
                    v-model="
                      internalBeneficiary.Trustee.BeneficiaryRelationshipId
                    "
                    :rules="[$rules.required]"
                    item-value="Id"
                    :item-text="
                      (item) => $helpers.translate(item, $vuetify.lang.current)
                    "
                    class="required"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-4 pt-8 justify-end">
          <v-btn
            @click="cancel"
            v-if="!mustAddBeneficiaries || hasBeneficiariesInProfile"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn
            @click="cancelAndLogout"
            v-if="mustAddBeneficiaries && !hasBeneficiariesInProfile"
            >{{ $t("globals.cancel") }}</v-btn
          >
          <v-btn
            :loading="saving"
            color="primary"
            class="mr-3"
            @click="save"
            :disabled="!valid"
          >
            {{ $t("globals.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>
<script>
import NoAutocompleteTextfield from "@/components/controls/NoAutocompleteTextfield.vue";
import { mapGetters, mapActions } from "vuex";
import CurrencyTextfield from "@/components/controls/CurrencyTextfield.vue";
export default {
  components: {
    NoAutocompleteTextfield,
    CurrencyTextfield,
  },
  props: {
    beneficiary: Object,
    dependants: Array,
    province: String,
    title: String,
    titleIcon: String,
    saving: Boolean,
    memberSaved: Boolean,
    remainingPercentage: Number,
    beneficiaries: Array,
  },
  data() {
    return {
      editedItem: null,
      benIsPerson: false,
      trusteeIsPerson: false,
      selectedDependant: null,
      addingNewBeneficiary: false,
      requiresTrusteeNote: "components.beneficiary_sba.minor_requres_trusttee",
      requiredBoolean: (v) =>
        // eslint-disable-next-line prettier/prettier
        v === true || v === false || this.$t("validation.required"),
      strokeOptions: {
        size: 16,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5,
        onBegin: () => {
          this.$refs.signaturePad.resizeCanvas();
        },
      },
      internalBeneficiary: {},
      beneficiarySelection: [
        { TextEn: "Primary", TextFr: "Primaire", Value: true },
        { TextEn: "Contingent", TextFr: "Contingent", Value: false },
      ],
      pickerDate: "1985-06",
      valid: false,
      dateMenu: false,
      dateMenu1: false,
      requiresTrustee: false,
      validateRelationship: true,
      options: {
        penColor: "#00f",
        onBegin: () => {
          this.$refs.signaturePad.resizeCanvas();
        },
      },
      mustBe18: ["AB", "MB", "ON", "PE", "QC", "SK"],
      mustBe19: ["BC", "NB", "NL", "NS", "NT", "NU", "YT"],
      newBeneficiary: {
        BeneficiaryType: "",
        FirstName: "",
        MiddleName: "",
        LastName: "",
        Relationship: "",
        BeneficiarySIN: null,
        DateOfBirth: "",
        Gender: "",
        LanguagePreference: "",
        Percentage: null,
      },
    };
  },
  computed: {
    ...mapGetters("common", [
      "enumModels",
      "userProfile",
      "mustAddBeneficiaries",
    ]),
    hasBeneficiariesInProfile() {
      return this.userProfile?.BeneficiaryStatement?.Beneficiaries?.length > 0;
    },
    beneficiaryTypes() {
      return this.enumModels.BeneficiaryType.filter((o) => {
        if (o.Name === "Estate" && this.isEstateAlreadyAdded) {
          return false;
        }
        return true;
      });
    },
    relationList() {
      return this.enumModels.BeneficiaryRelationship;
    },
    isCorporate() {
      return (
        this.internalBeneficiary.BeneficiaryTypeId ===
        this.enumModels.BeneficiaryType.find((r) => r.Name === "Corporate")?.Id
      );
    },
    isSpouseOrChild() {
      return (
        this.internalBeneficiary.BeneficiaryRelationshipId ===
          this.relationList.find((r) => r.Name === "Spouse")?.Id ||
        this.internalBeneficiary.BeneficiaryRelationshipId ===
          this.relationList.find((r) => r.Name === "Child")?.Id
      );
    },
    isSpouse() {
      return (
        this.internalBeneficiary.BeneficiaryRelationshipId ===
        this.relationList.find((r) => r.Name === "Spouse")?.Id
      );
    },
    isChild() {
      return (
        this.internalBeneficiary.BeneficiaryRelationshipId ===
        this.relationList.find((r) => r.Name === "Child")?.Id
      );
    },
    isPrimaryAlreadyAdded() {
      return (
        this.beneficiaries &&
        this.beneficiaries.filter((b) => b.IsPrimary).length !== 0
      );
    },
    isSecondaryAlreadyAdded() {
      return (
        this.beneficiaries &&
        this.beneficiaries.filter((b) => b.BeneficiaryType === "Secondary")
          .length !== 0
      );
    },
    isEstateAlreadyAdded() {
      const estateTypeId = this.enumModels.BeneficiaryType.find(
        (b) => b.Name === "Estate"
      )?.Id;
      if (this.beneficiary.BeneficiaryTypeId === estateTypeId) {
        return false;
      }
      return (
        this.beneficiaries &&
        this.beneficiaries.filter((b) => b.BeneficiaryTypeId === estateTypeId)
          .length !== 0
      );
    },
    internalDependants() {
      var isChild =
        this.internalBeneficiary.BeneficiaryRelationshipId ===
        this.relationList.find((r) => r.Name === "Child")?.Id;
      var isSpouse =
        this.internalBeneficiary.BeneficiaryRelationshipId ===
        this.relationList.find((r) => r.Name === "Spouse")?.Id;
      if (isChild) {
        if (this.beneficiaries) {
          return this.dependants.filter(
            (d) =>
              this.beneficiaries.findIndex(
                (b) => b.DependantIdentityId === d.Id
              ) === -1 && d.IsChild
          );
        } else {
          return this.dependants.filter((d) => d.IsChild);
        }
      }
      if (isSpouse) {
        if (this.beneficiaries) {
          return this.dependants.filter(
            (d) =>
              this.beneficiaries.findIndex(
                (b) => b.DependantIdentityId === d.Id
              ) === -1 && d.IsSpouse
          );
        } else {
          return this.dependants.filter((d) => d.IsSpouse);
        }
      }
      return [];
    },
    primaryPercentageTotal() {
      let hasPrimaryBeneficiary = this.beneficiaries?.some((b) => b.IsPrimary);
      if (!hasPrimaryBeneficiary) {
        return 0;
      }
      return this.beneficiaries?.reduce((acc, b) => {
        if (b.IsPrimary) {
          return acc + b.Percentage;
        }
        return acc;
      }, 0);
    },
    contingentPercentageTotal() {
      let hasContingentBeneficiary = this.beneficiaries?.some((b) => {
        return !b.IsPrimary;
      });
      if (!hasContingentBeneficiary) {
        return 0;
      }
      return this.beneficiaries?.reduce((acc, b) => {
        if (!b.IsPrimary) {
          return acc + b.Percentage;
        }
        return acc;
      }, 0);
    },
    minimumDateOfBirthAboveMajority() {
      let today = new Date();
      let minDate = new Date();
      if (this.mustBe18.includes(this.province)) {
        minDate.setFullYear(today.getFullYear() - 18);
      } else if (this.mustBe19.includes(this.province)) {
        minDate.setFullYear(today.getFullYear() - 19);
      }
      return minDate.toISOString();
    },
  },
  watch: {
    // internalBeneficiary: {
    //   handler(v) {
    //     this.internalBeneficiary = v;
    //     this.updatedRemainingPercentage =
    //       this.remainingPercentage - (this.internalBeneficiary.Percentage || 0);
    //     if (v && v.BeneficiaryType === "Primary") {
    //       this.internalBeneficiary.Percentage = 100;
    //       this.internalBeneficiary.Relationship =
    //         this.enumModels.Relationship.find((r) => r.Name === "Spouse")?.Id;
    //     }
    //     if (
    //       v &&
    //       v.BeneficiaryType === "Secondary" &&
    //       this.internalBeneficiary.Relationship === "Spouse"
    //     ) {
    //       this.internalBeneficiary.Relationship = null;
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    internalDependants() {
      if (this.internalDependants.length === 1) {
        let relationshipInDependants = this.enumModels.Relationship.find(
          (r) => r.Id === this.internalDependants[0].Relationship
        );
        if (relationshipInDependants) {
          if (
            relationshipInDependants.Name === "Child" ||
            relationshipInDependants.Name === "Daughter" ||
            relationshipInDependants.Name === "Son" ||
            relationshipInDependants.Name === "Spouse" ||
            relationshipInDependants.Name === "Husband" ||
            relationshipInDependants.Name === "Wife"
          ) {
            this.selectedDependant = this.internalDependants[0];
            this.fillUpDependantToBeneficiary(this.internalDependants[0]);
          }
        }
      }
    },
    "internalBeneficiary.DateOfBirth": {
      handler() {
        this.checkIfTrusteeRequired();
      },
    },
    memberSaved: {
      handler() {
        if (this.memberSaved && !this.saving) {
          this.cancel();
        }
      },
      deep: true,
    },
    beneficiary: {
      handler() {
        this.internalBeneficiary = JSON.parse(JSON.stringify(this.beneficiary));
        this.internalBeneficiary.IsPrimary = !this.isPrimaryAlreadyAdded;
        this.$refs.form.resetValidation();
      },
    },
    requiresTrustee() {
      if (!this.requiresTrustee) {
        this.internalBeneficiary.Trustee = {};
        this.internalBeneficiary.AddTrustee = false;
      }
    },
  },
  mounted() {
    this.internalBeneficiary = JSON.parse(JSON.stringify(this.beneficiary));
    if (this.internalBeneficiary.BeneficiaryTypeId) {
      this.internalBeneficiary.BeneficiaryType =
        this.enumModels.BeneficiaryType.find(
          (b) => b.Id === this.internalBeneficiary.BeneficiaryTypeId
        );
      if (this.internalBeneficiary.BeneficiaryType.Name === "Person") {
        this.benIsPerson = true;
      }
    }
    if (!this.internalBeneficiary.Trustee) {
      this.internalBeneficiary.Trustee = {};
    }
    if (this.internalBeneficiary.Trustee?.BeneficiaryTypeId) {
      this.internalBeneficiary.Trustee.BeneficiaryType =
        this.enumModels.BeneficiaryType.find(
          (b) => b.Id === this.internalBeneficiary.Trustee.BeneficiaryTypeId
        );
      if (this.internalBeneficiary.Trustee.BeneficiaryType.Name === "Person") {
        this.trusteeIsPerson = true;
      }
    }
    if (this.dependants) {
      this.dependants.forEach((d) => {
        d.FullName = d.FirstName + " " + d.LastName;
        d.IsChild = this.enumModels?.Relationship?.find(
          (r) => r.Id === d.Relationship
        )?.IsChild;
        d.IsSpouse = this.enumModels?.Relationship?.find(
          (r) => r.Id === d.Relationship
        )?.IsSpouse;
      });
    }
    if (this.beneficiaries && this.beneficiaries.length > 0) {
      const estateTypeId = this.enumModels.BeneficiaryType.find(
        (b) => b.Name === "Estate"
      )?.Id;
      this.beneficiaries.forEach((b) => {
        if (b.BeneficiaryTypeId === estateTypeId) {
          b.IsEstate = true;
        }
      });
    }
  },
  methods: {
    ...mapActions("common", ["clearUserProfileData"]),
    ...mapActions("auth", ["logout", "setUser", "setIsLoggedIn"]),
    setBeneficiaryType(event) {
      this.internalBeneficiary.BeneficiaryTypeId = event.Id;
      this.internalBeneficiary.IsPrimary = this.primaryPercentageTotal < 1;
      if (event.Name === "Person") {
        this.benIsPerson = true;
      } else {
        this.benIsPerson = false;
        this.internalBeneficiary.BeneficiaryRelationshipId = null;
      }
    },
    setTrusteeType() {
      this.trusteeIsPerson = true;
      this.internalBeneficiary.Trustee.BeneficiaryTypeId =
        this.enumModels.BeneficiaryType.find((b) => b.Name === "Person")?.Id;
    },
    cancelAndLogout() {
      this.clearUserProfileData();
      this.logout();
      this.setUser(undefined);
      this.setIsLoggedIn(false);
      this.$router.push({ name: "login" });
    },
    checkIfTrusteeRequired() {
      if (this.internalBeneficiary.DateOfBirth) {
        let dob = new Date(this.internalBeneficiary.DateOfBirth);
        let today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        let m = today.getMonth() - dob.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < dob.getDate())) {
          age--;
        }
        if (this.mustBe18.includes(this.province)) {
          this.requiresTrustee = age < 18;
          this.internalBeneficiary.AddTrustee = this.requiresTrustee
            ? true
            : this.internalBeneficiary.AddTrustee ?? false;
        } else if (this.mustBe19.includes(this.province)) {
          this.requiresTrustee = age < 19;
          this.internalBeneficiary.AddTrustee = this.requiresTrustee
            ? true
            : this.internalBeneficiary.AddTrustee ?? false;
        }
      } else {
        this.requiresTrustee = false;
      }
      if (this.internalBeneficiary.IsDisabled) {
        this.requiresTrustee = true;
        this.internalBeneficiary.AddTrustee = true;
        this.requiresTrusteeNote =
          "components.beneficiary_sba.disabled_requires_trusttee";
      }
      if (this.internalBeneficiary.AddTrustee) {
        this.setTrusteeType();
      }
    },
    fillUpDependantToBeneficiary(dependant) {
      if (!dependant) return;
      this.$set(this.internalBeneficiary, "FirstName", dependant.FirstName);
      this.$set(this.internalBeneficiary, "MiddleName", dependant.MiddleName);
      this.$set(this.internalBeneficiary, "LastName", dependant.LastName);
      this.$set(this.internalBeneficiary, "BeneficiarySIN", dependant.SIN);
      this.$set(
        this.internalBeneficiary,
        "IsDisabled",
        dependant.Qualification?.ProofOfDisability ? true : false
      );
      this.$set(this.internalBeneficiary, "DependantIdentityId", dependant.Id);
      this.$set(
        this.internalBeneficiary,
        "DateOfBirth",
        dependant.DateOfBirth.split("T")[0]
      );
      let relationshipInBeneficiaries =
        this.enumModels.BeneficiaryRelationship.find(
          (r) => r.Id === dependant.Relationship
        );
      let relationshipInDependants = this.enumModels.Relationship.find(
        (r) => r.Id === dependant.Relationship
      );
      if (relationshipInBeneficiaries) {
        this.$set(
          this.internalBeneficiary,
          "BeneficiaryRelationshipId",
          relationshipInBeneficiaries.Id
        );
      } else if (relationshipInDependants) {
        let rel = this.enumModels.BeneficiaryRelationship.find(
          (r) => r.Name === relationshipInDependants.Name
        );
        if (!rel) {
          if (
            relationshipInDependants.Name === "Daughter" ||
            relationshipInDependants.Name === "Son"
          ) {
            rel = this.enumModels.BeneficiaryRelationship.find(
              (r) => r.Name === "Child"
            );
          }
          if (
            relationshipInDependants.Name === "Husband" ||
            relationshipInDependants.Name === "Wife"
          ) {
            rel = this.enumModels.BeneficiaryRelationship.find(
              (r) => r.Name === "Spouse"
            );
          }
        }
        if (!rel) {
          rel = this.enumModels.BeneficiaryRelationship.find(
            (r) => r.Name === "Other"
          );
        }
        this.$set(
          this.internalBeneficiary,
          "BeneficiaryRelationshipId",
          rel?.Id
        );
      } else
        this.$set(this.internalBeneficiary, "BeneficiaryRelationshipId", null);
      // this.$set(this.internalBeneficiary, "GenderId", dependant.Gender);
      this.addingNewBeneficiary = false;
    },
    enableAddNewBeneficiary() {
      this.addingNewBeneficiary = true;
      this.internalBeneficiary.FirstName = "";
      this.internalBeneficiary.MiddleName = "";
      this.internalBeneficiary.LastName = "";
      this.internalBeneficiary.BeneficiarySIN = null;
      this.internalBeneficiary.DateOfBirth = "";
      // this.internalBeneficiary.GenderId = null;
      this.internalBeneficiary.Percentage = null;
      this.internalBeneficiary.AddTrustee = false;
      this.internalBeneficiary.Trustee = {};
      this.internalBeneficiary.IsPrimary = this.primaryPercentageTotal < 1;
      this.internalBeneficiary.IsRevocable = true;
    },
    cancel() {
      this.$emit("cancel-dialog");
    },
    save() {
      if (this.internalBeneficiary.AddTrustee) {
        if (
          this.internalBeneficiary.Trustee?.BeneficiaryType?.Name !== "Person"
        ) {
          this.internalBeneficiary.Trustee.MiddleName = null;
        }
      } else {
        this.internalBeneficiary.Trustee = null;
      }
      this.$emit("save-beneficiary", this.internalBeneficiary);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    // },
    change() {
      this.options = {
        penColor: "#00f",
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f",
      };
    },
  },
};
</script>
<style scoped>
.right-text-input >>> input {
  text-align: right;
}
.v-input--is-disabled {
  color: rgba(0, 0, 0, 0.87);
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: "100%";
  padding: 8px 16px;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>
